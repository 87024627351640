import useSettings from 'app/hooks/useSettings';

const RcpLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <img
      src="/assets/images/rcp_icon_white.png"
      alt="RCP"
      width="24px"
      height="24px"
      className={className}
    >
    </img>
  );
};

export default RcpLogo;
