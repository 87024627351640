import useAuth from 'app/hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { apiUrl } from 'app/utils/utils';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  if (isAuthenticated) return <>{children}</>;

  //return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
  let loginUrl = apiUrl("/auth/login");
  console.log("login debug 1 - AuthGuard ", loginUrl);
  return <Navigate replace to={loginUrl} state={{ from: pathname }} />;
};

export default AuthGuard;
