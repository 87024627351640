import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';

// https://github.com/ctimmerm/axios-mock-adapter
// https://github.com/ctimmerm/axios-mock-adapter/issues/357

const originalAdapter1 = axios.defaults.adapter;
console.log("originalAdapter1",originalAdapter1);

const Mock = new MockAdapter(axios, { onNoMatch: "passthrough" } );

/*
Mock.restore();
const originalAdapter2 = axios.defaults.adapter;
Mock.resetHandlers();
console.log("originalAdapter2",originalAdapter2);
Mock.originalAdapter = originalAdapter2;
*/

//Mock.originalAdapter = originalAdapter1;

//Mock.originalAdapter = function() { console.log("originalAdapter")  };
export default Mock;
