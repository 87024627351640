//import * as React from "react";

import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
//import routes from './routes';
import GenRoutes from './routes';
import '../fake-db';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import React, { useState, useEffect, useContext } from "react"
import axios from 'axios'
import { apiUrl } from 'app/utils/utils';
import { useCookieState } from "use-cookie-state";


const queryClient = new QueryClient()
export const HubIdContext = React.createContext({
  hubId: "",
  setHubId: () => {},
  hubList: {},
  setHubList: () => {},
  currentHubSelectionMethod: "",
  setCurrentHubSelectionMethod: () => {}
});

const App = () => {
  //const content = useRoutes(routes);
  const [actions, setActions] = useState({});


  const [hubId, setHubId] = useCookieState("hubid", "LM8HA1" );
  const [hubList, setHubList] = useCookieState("hubList", {} );
  const [currentHubSelectionMethod, setCurrentHubSelectionMethod] = useCookieState("currentHubSelectionMethod", "single" );
  const hubIdContext = { hubId, setHubId, hubList, setHubList, currentHubSelectionMethod, setCurrentHubSelectionMethod };
  console.log(hubIdContext);

  if ( typeof hubList == "string" ) {
    try {
      setHubList(JSON.parse(hubList));
    } catch(e) {
      console.log(e);
    }
  }


  useEffect(() => {
    (async () => {
      try {
        console.log("AuthNavigations", "useEffect");

        const response = await axios.get(  //todo(arm)(med) - use hubSupportalApi
          apiUrl("/api/hs_action")
        );

        //console.log("AuthNavigations","hs_action", response);
        //console.log("AuthNavigations","hs_action.data", response.data);
        //console.log("AuthNavigations","hs_action.data.data", response.data.data);

        setActions(response.data.data);

      } catch (err) {
        console.error(err);
      }
    })();
  }, []);
  const content = useRoutes(GenRoutes(actions));

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const foo = params.get('hubId');  
  console.log("queryParm",foo)
  if ( foo && (foo != hubId || currentHubSelectionMethod != "single") ) {
    setHubId(foo);
    setCurrentHubSelectionMethod("single");
  }

  return (

    <SettingsProvider>
      <AuthProvider>
        <HubIdContext.Provider value={hubIdContext}>
          <MatxTheme>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <QueryClientProvider client={queryClient} >
                {content}
              </QueryClientProvider>
            </LocalizationProvider>
          </MatxTheme>
        </HubIdContext.Provider>
      </AuthProvider>
    </SettingsProvider>

  );
};

export default App;
