import { createSlice } from '@reduxjs/toolkit'

// doc(arm) https://react-redux.js.org/tutorials/quick-start

export const hubdataSlice = createSlice({
  name: 'hubdata',
  initialState: {
    hubid: "LM8HA1E8Z60",
  },
  reducers: {
    update_hubid: (state, hubid) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.hubid = hubid;
    },
  },
})

// Action creators are generated for each case reducer function
export const { update_hubid } = hubdataSlice.actions

export default hubdataSlice.reducer